<template>
  <div id="footer_menu1"  class="hoc clear" style="width: 100%; background: #03a9f48c; margin-top: 10px;">
    <footer class="row_footer1">
      <div class="col_footer1_menu w-25">
        <div class="d-flex justify-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.qr.vcard"
            target="_blank"
            style="text-align: left"
          >
            <img
              style="border-radius: 5px"
              src="/img/footer/qr-tai-VCard.png"
            />
          </a>
          <div
            class="d-flex flex-column justify-space-around"
            style="margin-left: 10px"
          >
            <img
              class="imgqr"
              style="width: 50px; margin: auto"
              src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1724991198092JzTvF"
            />

            <a
              href="https://apps.apple.com/vn/app/vcards/id1565903620?l=vi"
              target="_blank"
              style="text-align: left"
            >
              <img
                class="imgqr"
                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=16957034549947vdJF"
              />
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.qr.vcard"
              target="_blank"
              style="text-align: left; margin-top: 10px"
            >
              <img
                class="imgqr"
                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695703486057vHWBF"
              />
            </a>
          </div>
        </div>
      </div>

      <div class="col_footer1_menu w-25">
        <div class="item-menu item-menu--style">
          <a
            class="m-auto h-58p"
            target="_blank"
            href="https://drive.google.com/file/d/16XDp2WrZdJ6O2Rdl7Arwgz-GfKcnipFx/view?usp=sharing"
          >
            <img
              src="/img/footer/NHNN_trans.png"
              style="width: 87px; height: 51px"
            />
          </a>

          <a
            target="_blank"
            href="https://drive.google.com/file/d/16XDp2WrZdJ6O2Rdl7Arwgz-GfKcnipFx/view?usp=sharing"
          >
          {{ 
           currentLanguage.footer_text_row_footer1_nganHang }} 
           <br>
            {{ 
           currentLanguage.footer_text_row_footer1_giayPhep
          }}
          </a>
        </div>
      </div>
      <div class="col_footer1_menu w-25">
        <div class="item-menu item-menu--style">
          <a
            id="bocongthuong"
            href="https://vimass.vn/vmbank/services/media/viewFile?id=21_10_5_Giay_phep_kinh_doanh_san_pham_dich_vu_MMDS.pdf"
            target="_blank"
            class="m-auto"
          >
            <img
              src="/img/footer/Bieu_Trung_BCY.png"
              style="width: 55px; height: 58px"
            />
          </a>
          <div class="w-100 text-center">
            <a
              class="w-100 text-center p-right"
              href="https://vimass.vn/vmbank/services/media/viewFile?id=21_10_5_Giay_phep_kinh_doanh_san_pham_dich_vu_MMDS.pdf"
              target="_blank"
              > {{ currentLanguage.footer_text_row_footer1_giayPhepKinhDoanh }}</a
            >
          </div>
          <div class="w-100 text-center">
            <a
              class="w-100 text-center"
              href="https://drive.google.com/file/d/1ch2fOQ3ayG5Hi07-NoncehexSMEExAzk/view?usp=sharing"
              target="_blank"
            >
            {{ currentLanguage.footer_text_row_footer1_giayPhepXuatNhapKhau }}
             </a
            >
          </div>
        </div>
      </div>

      <div class="col_footer1_menu w-25">
        <div class="w-100 text-center">
          <a
            id="bocongthuong"
            href="http://online.gov.vn/Home/WebDetails/65124"
            target="_blank"
          >
            <img
              style="padding-left: 10px; width: 128px; margin-bottom: 12px"
              src="/img/footer/bct.png"
            />
          </a>
        </div>
        <div class="w-100 text-center">
          <a class="me-10p" href="https://qrvn.vn/" target="_blank"
            >{{currentLanguage.footer_text_row_footer1_xemThem}}https://qrvn.vn</a
          >
        </div>
        <div class="w-100 text-center">
          <a class="me-10p" href="https://vimass.vn/vidientu" target="_blank"
            >{{currentLanguage.footer_text_row_footer1_xemThem}}
            https:/vimass.vn/vidientu</a
          >
        </div>
      </div>
    </footer>

    <footer
      id="footer"
      class="hoc clear"
      style="background: #007abe; float: none"
    >
      <!-- ################################################################################################ -->
      <div class="container-footer">
        <div class="footer-bolck footer-bolck--0">
          <div class="footer-bolck__item">
            <span href="/"
              ><strong>{{currentLanguage.footer_text_tenCty}}</strong>
              <img
                src="img/footer/logovimass.png"
                style="width: 62px; vertical-align: inherit ; margin-left: 10px;"
            /></span>
          </div>
          <div class="footer-bolck__item">
            <span>{{currentLanguage.footer_text_hoiSoHaNoi}}</span>
          </div>
            <div class="footer-bolck__item">
            <a href="https://qrvn.vn/" target="_blank"
              ><strong>{{currentLanguage.footer_text_ctyQRVN}} </strong>
              <img
                src="https://web.vimass.vn/vmbank/services/media/getImage?id=logoqrvnnew.png"
                style="height: 17px; vertical-align: inherit; margin-left: 10PX;"
              /> 
            </a>
          </div>
          <div class="footer-bolck__item">
            <span>{{currentLanguage.footer_text_hoiSoHaNoiQRVN}}</span>
          </div>
        </div>
        <div class="footer-bolck footer-bolck--1">
          <div class="footer-bolck__item">
            <a
              id="urlFooterChuongTrinhTaiTro"
              href="vi/pos"
              target="_blank"
            >
              <i class="fas fa-caret-right" style="color: #ffffff"></i>
              {{ currentLanguage.item_footer_0 }}
  
             </a
            >
          </div>

          <div class="footer-bolck__item">
            <a id="urlFooterTheDaNang" href="vi/dichvuthuchiho" target="_blank">
              <i class="fas fa-caret-right" style="color: #ffffff"></i>
              {{currentLanguage.item_footer_1  }}
            

              </a
            >
          </div>
          <div class="footer-bolck__item">
            <a id="urlFooterTheDaNang" href="vi/diemthanhtoanvadinhdanh" target="_blank">
              <i class="fas fa-caret-right" style="color: #ffffff"></i>
             
              {{currentLanguage.item_footer_2}}
              </a
            >
          </div>

          <div class="footer-bolck__item">
            <a id="urlFooterThietBi" href="vi/giaiphapchuyendoiso" target="_blank">
              <i class="fas fa-caret-right" style="color: #ffffff"></i>
              {{currentLanguage.item_footer_3}}
         
              </a
            >
          </div>

        

        </div>
        <div class="footer-bolck footer-bolck--2">
        

          <div class="footer-bolck__item">
            <a
              id="urlFooterDiemThanhToan"
              href="vi/giaoducvayte"
              target="_blank"
            >
              <i class="fas fa-caret-right" style="color: #ffffff"></i>
              {{currentLanguage.item_footer_4}} 
            
              </a
            >
          </div>


          <div class="footer-bolck__item">
            <a
              id="urlFooterDiemThanhToan"
              href="vi/giaothongvadichvucong"
              target="_blank"
            >
              <i class="fas fa-caret-right" style="color: #ffffff"></i>
              {{currentLanguage.item_footer_5}}
            
              </a
            >
          </div>

          <div class="footer-bolck__item">
            <a id="urlFooterDiemRaVao" href="vi/thietbivadichvu" target="_blank">
              <i class="fas fa-caret-right" style="color: #ffffff"></i>
              {{currentLanguage.item_footer_6}}</a
            >
          </div>

        


          <div class="footer-bolck__item">
            <a id="urlFooterCongTy" href="vi/congty" target="_blank">
              <i class="fas fa-caret-right" style="color: #ffffff"></i>
              {{currentLanguage.item_footer_7}} </a
            >
          </div>
        </div>
      </div>
      <!-- ################################################################################################ -->
    </footer>
  </div>
</template>
<script>
import {languages} from "@/components/languages"

export default {
 
  data() {
    return {
      currentLanguage: languages.vi,
    };
  },
  created() {
  const savedLanguage = localStorage.getItem("languages");
  if (savedLanguage && languages[savedLanguage]) {
    this.currentLanguage = languages[savedLanguage];
  }
},
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
    },
  },
};
</script>
