
<template>
  <header>
    <label for="toggle-1" class="toggle-menu" @click="toggleMenu"
      ><ul>
        <li></li>
        <li></li>
        <li></li></ul
    ></label>
    <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
    <nav
      class="nav_center"
      style="padding: 0"
      :style="{ display: isMenuVisible ? 'block' : 'none' }"
    >
      <ul style="list-style: none">
       
        <li>
          <a id="banHangQRdong" href="/pos"
            >{{ currentLanguage.item_footer_0 }}</a
          >
        </li>
        <li>
          <a id="dichVuThuChiHo" href="/dichvuthuchiho">{{
            currentLanguage.header_text_thuChiHoQuaAPI
          }}</a>
        </li>
        <li>
          <a id="diemThanhToanVaDinhDanh" href="/diemthanhtoanvadinhdanh">{{
            currentLanguage.item_footer_2
          }}</a>
        </li>
        <li>
          <a id="giaiPhapChuyenDoiSo" href="/giaiphapchuyendoiso">{{ currentLanguage.header_text_dinhDanhXacThuc }}</a>
        </li>
        <li>
          <a id="Gd_Yte" href="/giaoducvayte"

            >{{ currentLanguage.header_text_diemThanhToan_giaoDuc }}, {{ currentLanguage.header_text_diemThanhToan_sucKhoe }} 

          </a
          >
        </li>
        <li>
          <a id="Gt_Dvc" href="/giaothongvadichvucong"

            >{{ currentLanguage.header_text_diemThanhToan_diChuyen }}, {{ currentLanguage.header_text_dichVuCong }} 

          </a
          >
        </li>
     

        <li>
          <a id="thietBiVaDichVu"  href="/thietbivadichvu"
            >{{ currentLanguage.header_text_thietBi_Dichvu }}
          </a>
       
        </li>

        <li>
          <a id="congTy" href="/congty">{{ currentLanguage.footer_text_cty }}</a>
        </li>
      </ul>
    </nav>
    <div class="menu_right" style="display: flex">
      <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
        ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
      </a>
      <a @click="changeLanguage('en')" class="lang" style="padding: 5px"
        ><img style="width: 25px" src="img/img-item-vimass-2022/co/us.png" />
      </a>
      <a @click="changeLanguage('cn')" class="lang" style="padding: 5px"
        ><img style="width: 25px" src="img/img-item-vimass-2022/co/cn.jpg" />
      </a>
      <a @click="changeLanguage('kr')" class="lang" style="padding: 5px"
        ><img style="width: 25px" src="img/img-item-vimass-2022/co/kr.jpg" />
      </a>
 
      <span
        class="clickShow"
        style="color: #ffffff; cursor: pointer; margin: auto; padding: 5px"
        ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
      ></span>
      <div class="showCo" style="display: none; margin: auto">
        <a onclick="changeLang('us')" class="lang"
          ><img src="img/img-item-vimass-2022/co/indo.jpg" />
        </a>
        <a onclick="changeLang('us')" class="lang"
          ><img src="img/img-item-vimass-2022/co/tl.png" />
        </a>
        <a onclick="changeLang('us')" class="lang"
          ><img src="img/img-item-vimass-2022/co/thailan.png" />
        </a>
        <a onclick="changeLang('us')" class="lang"
          ><img src="img/img-item-vimass-2022/co/hu.jpg" />
        </a>
      </div>
      <a id="urlHome" href="/" style="margin: 0 10px"
        ><img
          style="width: 35px; height: 35px"
          src="img/home-icon-white-8.jpg"
        />
      </a>
    </div>
  </header>
  <div class="wrapper row3">
    <main class="hoc container-fluid clear bv">
      <div style="min-height: 500px" class="padding-top15">
        <div class="title-bai-viet" v-html="titleHeThong"></div>
        <div v-html="contentHeThong"></div>
      </div>
    </main>
  </div>
  <!-- <FooterView></FooterView> -->
  <div id="footer_menu1"  class="hoc clear" style="width: 100%; background: #03a9f48c; margin-top: 10px;">
    <footer class="row_footer1">
      <div class="col_footer1_menu w-25">
        <div class="d-flex justify-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.qr.vcard"
            target="_blank"
            style="text-align: left"
          >
            <img
              style="border-radius: 5px"
              src="/img/footer/qr-tai-VCard.png"
            />
          </a>
          <div
            class="d-flex flex-column justify-space-around"
            style="margin-left: 10px"
          >
            <img
              class="imgqr"
              style="width: 50px; margin: auto"
              src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1724991198092JzTvF"
            />

            <a
              href="https://apps.apple.com/vn/app/vcards/id1565903620?l=vi"
              target="_blank"
              style="text-align: left"
            >
              <img
                class="imgqr"
                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=16957034549947vdJF"
              />
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.qr.vcard"
              target="_blank"
              style="text-align: left; margin-top: 10px"
            >
              <img
                class="imgqr"
                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695703486057vHWBF"
              />
            </a>
          </div>
        </div>
      </div>

      <div class="col_footer1_menu w-25">
        <div class="item-menu item-menu--style">
          <a
            class="m-auto h-58p"
            target="_blank"
            href="https://drive.google.com/file/d/16XDp2WrZdJ6O2Rdl7Arwgz-GfKcnipFx/view?usp=sharing"  >
            <img
              src="/img/footer/NHNN_trans.png"
              style="width: 87px; height: 51px"
            />
          </a>

          <a
            target="_blank"
            href="https://drive.google.com/file/d/16XDp2WrZdJ6O2Rdl7Arwgz-GfKcnipFx/view?usp=sharing" >
          {{ 
           currentLanguage.footer_text_row_footer1_nganHang }} 
           <br>
            {{ 
           currentLanguage.footer_text_row_footer1_giayPhep
          }}
          </a>
        </div>
      </div>
      <div class="col_footer1_menu w-25">
        <div class="item-menu item-menu--style">
          <a
            id="bocongthuong"
            href="https://vimass.vn/vmbank/services/media/viewFile?id=21_10_5_Giay_phep_kinh_doanh_san_pham_dich_vu_MMDS.pdf"
            target="_blank"
            class="m-auto"
          >
            <img
              src="/img/footer/Bieu_Trung_BCY.png"
              style="width: 55px; height: 58px"
            />
          </a>
          <div class="w-100 text-center">
            <a
              class="w-100 text-center p-right"
              href="https://vimass.vn/vmbank/services/media/viewFile?id=21_10_5_Giay_phep_kinh_doanh_san_pham_dich_vu_MMDS.pdf"
              target="_blank"
              > {{ currentLanguage.footer_text_row_footer1_giayPhepKinhDoanh }}</a
            >
          </div>
          <div class="w-100 text-center">
            <a
              class="w-100 text-center"
              href="https://drive.google.com/file/d/1ch2fOQ3ayG5Hi07-NoncehexSMEExAzk/view?usp=sharing"
              target="_blank"
            >
            {{ currentLanguage.footer_text_row_footer1_giayPhepXuatNhapKhau }}
             </a
            >
          </div>
        </div>
      </div>

      <div class="col_footer1_menu w-25">
        <div class="w-100 text-center">
          <a
            id="bocongthuong"
            href="http://online.gov.vn/Home/WebDetails/65124"
            target="_blank"
          >
            <img
              style="padding-left: 10px; width: 128px; margin-bottom: 12px"
              src="/img/footer/bct.png"
            />
          </a>
        </div>
        <div class="w-100 text-center">
          <a class="me-10p" href="https://qrvn.vn/" target="_blank"
            >{{currentLanguage.footer_text_row_footer1_xemThem}}https://qrvn.vn</a
          >
        </div>
        <div class="w-100 text-center">
          <a class="me-10p" href="https://vimass.vn/vidientu" target="_blank"
            >{{currentLanguage.footer_text_row_footer1_xemThem}}
            https:/vimass.vn/vidientu</a
          >
        </div>
      </div>
    </footer>

    <footer
      id="footer"
      class="hoc clear"
      style="background: #007abe; float: none"
    >
      <!-- ################################################################################################ -->
      <div class="container-footer">
        <div class="footer-bolck footer-bolck--0">
          <div class="footer-bolck__item">
            <span href="/"
              ><strong>{{currentLanguage.footer_text_tenCty}}</strong>
              <img
                src="img/footer/logovimass.png"
                style="width: 62px; vertical-align: inherit ; margin-left: 10px;"
            /></span>
          </div>
          <div class="footer-bolck__item">
            <span>{{currentLanguage.footer_text_hoiSoHaNoi}}</span>
          </div>
            <div class="footer-bolck__item">
            <a href="https://qrvn.vn/" target="_blank"
              ><strong>{{currentLanguage.footer_text_ctyQRVN}} </strong>
              <img
                src="https://web.vimass.vn/vmbank/services/media/getImage?id=logoqrvnnew.png"
                style="height: 17px; vertical-align: inherit; margin-left: 10PX;"
              /> 
            </a>
          </div>
          <div class="footer-bolck__item">
            <span>{{currentLanguage.footer_text_hoiSoHaNoiQRVN}}</span>
          </div>
        </div>
        <div class="footer-bolck footer-bolck--1">
          <div class="footer-bolck__item">
            <a
              id="urlFooterChuongTrinhTaiTro"
              href="vi/pos"
              target="_blank"
            >
              <i class="fas fa-caret-right" style="color: #ffffff"></i>
              {{ currentLanguage.item_footer_0 }}
  
             </a
            >
          </div>

          <div class="footer-bolck__item">
            <a id="urlFooterTheDaNang" href="vi/dichvuthuchiho" target="_blank">
              <i class="fas fa-caret-right" style="color: #ffffff"></i>
              {{currentLanguage.item_footer_1  }}
            

              </a
            >
          </div>
          <div class="footer-bolck__item">
            <a id="urlFooterTheDaNang" href="vi/diemthanhtoanvadinhdanh" target="_blank">
              <i class="fas fa-caret-right" style="color: #ffffff"></i>
             
              {{currentLanguage.item_footer_2}}
              </a
            >
          </div>

          <div class="footer-bolck__item">
            <a id="urlFooterThietBi" href="vi/giaiphapchuyendoiso" target="_blank">
              <i class="fas fa-caret-right" style="color: #ffffff"></i>
              {{currentLanguage.item_footer_3}}
         
              </a
            >
          </div>

        

        </div>
        <div class="footer-bolck footer-bolck--2">
        

          <div class="footer-bolck__item">
            <a
              id="urlFooterDiemThanhToan"
              href="vi/giaoducvayte"
              target="_blank"
            >
              <i class="fas fa-caret-right" style="color: #ffffff"></i>
              {{currentLanguage.item_footer_4}} 
            
              </a
            >
          </div>


          <div class="footer-bolck__item">
            <a
              id="urlFooterDiemThanhToan"
              href="vi/giaothongvadichvucong"
              target="_blank"
            >
              <i class="fas fa-caret-right" style="color: #ffffff"></i>
              {{currentLanguage.item_footer_5}}
            
              </a
            >
          </div>

          <div class="footer-bolck__item">
            <a id="urlFooterDiemRaVao" href="vi/thietbivadichvu" target="_blank">
              <i class="fas fa-caret-right" style="color: #ffffff"></i>
              {{currentLanguage.item_footer_6}}</a
            >
          </div>

        


          <div class="footer-bolck__item">
            <a id="urlFooterCongTy" href="vi/congty" target="_blank">
              <i class="fas fa-caret-right" style="color: #ffffff"></i>
              {{currentLanguage.item_footer_7}} </a
            >
          </div>
        </div>
      </div>
      <!-- ################################################################################################ -->
    </footer>
  </div>
</template>

<script>
import { languages } from "@/components/languages";
import axios from "axios";
import { apiChiTietBaiViet } from "@/components/listService";
// import FooterView from "@/views/Vimass/Footer/FooterView.vue";
export default {
  components: {
    // FooterView,
  },
  data() {
    return {
      currentLanguage: languages.vi,
      isMenuVisible: false,

      lang: "",
      base64: "",
      base64Title: "",
      //title
      titleHeThong: "",
      //content
      contentHeThong: "",
      hasMounted: false,
      idBaiViet: "",
    };
  },

  mounted() {
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }

    let url = window.location.href;
    let pathArray = url.split("/");
    let lang1 = pathArray[pathArray.length - 3];
    localStorage.setItem("languages", lang1);
    const lang = localStorage.getItem("languages");
    console.log(lang1 + "   a");
    if (this.lang == "vi") {
      document.title = "Vimass.vn - Bài viết";
    } else if (this.lang == "en") {
      document.title = "Vimass.vn - Article details";
    } else if (this.lang == "cn") {
      document.title = "Vimass.vn - 帖子";
    } else if (this.lang == "kr") {
      document.title = "Vimass.vn - 게시물";
    }
    this.lang = lang;
    if (!this.hasMounted) {
      this.hasMounted = true;
      this.idBaiViet = this.$route.params.id;
      if (this.lang) {
        // Kiểm tra giá trị của this.lang trước khi sử dụng
        this.$router.push({
          path: "/" + this.lang + "/chi-tiet.html/" + this.idBaiViet,
        });
      }
    }

      document.getElementById("urlHome").setAttribute("href", this.lang);
      // document.getElementById("qrAmThanh").setAttribute("href", lang + "/qramthanh");

      document.getElementById("banHangQRdong").setAttribute("href", lang + "/pos");

      document.getElementById("dichVuThuChiHo").setAttribute("href", lang + "/dichvuthuchiho");

      document.getElementById("giaiPhapChuyenDoiSo").setAttribute("href", lang + "/giaiphapchuyendoiso");

      document.getElementById("Gd_Yte").setAttribute("href", lang + "/giaoducvayte");
      document.getElementById("Gt_Dvc").setAttribute("href", lang + "/giaothongvadichvucong");
      document.getElementById("diemThanhToanVaDinhDanh").setAttribute("href", lang + "/diemthanhtoanvadinhdanh");

      document.getElementById("thietBiVaDichVu").setAttribute("href", lang + "/thietbivadichvu");
      document.getElementById("congTy").setAttribute("href", lang + "/congty");

    if (this.$refs.manHinhChinh) {
      const imgElement = this.$refs.manHinhChinh;
      imgElement.addEventListener("click", this.handleClick);
      console.log("Ref tồn tại");
    } else {
      console.log("Ref không tồn tại");
    }
    if (this.lang && languages[this.lang]) {
      this.currentLanguage = languages[this.lang];
    }

    this.performPostRequest(this.idBaiViet);


  },

  methods: {
    handleClick() {
      // Xử lý sự kiện click ở đây
      console.log("Đã click vào màn hình chính");
    },

    toggleMenu() {
      this.isMenuVisible = true;
    },
    changeLanguage(lang) {
      // this.currentLanguage = languages[lang];
      // localStorage.setItem("languages", lang);
      // window.location.reload();
      // const currentPath = this.$route.fullPath;
      const idBaiViet = this.idBaiViet; // Đảm bảo biến idBaiViet đã được định nghĩa ở nơi khác

      // Cập nhật URL mới với ngôn ngữ và id bài viết
      const newPath = "/" + lang + "/chi-tiet.html/" + idBaiViet;

      // Reload trang với URL mới
      window.location.href = newPath;
    },

    async performPostRequest(id) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        } else if (this.lang === "kr") {
          this.base64 = response.data.result.content_kr;
          this.base64Title = response.data.result.title_kr;
        } else {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        }
        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        this.titleHeThong = decodeURIComponent(escape(title));
        this.contentHeThong = decodeURIComponent(escape(decodedString));
      } catch (error) {
        console.error("Error:", error);
      }
    },
  },
};
</script>