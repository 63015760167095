<template>
  <header class="d-flex justify-content-between align-items-center">
    <label for="toggle-1" class="toggle-menu" @click="toggleMenu"
      ><ul>
        <li></li>
        <li></li>
        <li></li></ul
    ></label>
    <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
    <nav
      class="nav_center"
      style="padding: 0"
      :style="{ display: isMenuVisible ? 'block' : 'none' }"
    >
      <ul style="list-style: none">
        <!-- <li
          :class="{ topmenuactive: activeTab === 'gioiThieu' }"
          @click="changeTab('gioiThieu')"
        >
          <a id="urlGioithieu" href="giaothongvadichvucong#gioithieu/">{{
            currentLanguage.header_text_gioiThieu
          }}</a>
        </li> -->
       
        <li
          :class="{ topmenuactive: activeTab === 'GiaoThong' }"
          @click="changeTab('GiaoThong')"
        >
          <a id="urlBai1" href="giaothongvadichvucong#giaothong/">{{
            currentLanguage.header_text_diemThanhToan_diChuyen
          }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'DichVuCong' }"
          @click="changeTab('DichVuCong')"
        >
          <a id="urlBai2" href="giaothongvadichvucong#dichvucong/">{{
            currentLanguage.header_text_theDaNang_dichViCong
          }}</a>
        </li>

        <li
          :class="{ topmenuactive: activeTab === 'lienhe' }"
          @click="changeTab('lienhe')"
        >
          <a id="urlBai3" href="giaothongvadichvucong#lienhe/">{{
            currentLanguage.header_text_lienHe
          }}</a>
        </li>
      </ul>
    </nav>
    <div class="menu_right" style="display: flex">
      <div class="menu_co_right" style="display: flex">
    <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
      ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
    </a>
    <a @click="changeLanguage('en')" class="lang" style="padding: 5px"
      ><img style="width: 25px" src="img/img-item-vimass-2022/co/us.png" />
    </a>
    <a @click="changeLanguage('cn')" class="lang" style="padding: 5px"
      ><img style="width: 25px" src="img/img-item-vimass-2022/co/cn.jpg" />
    </a>
    <a @click="changeLanguage('kr')" class="lang" style="padding: 5px"
      ><img style="width: 25px ; " src="img/img-item-vimass-2022/co/kr.jpg" />
    </a>

   
 
    <span
      class="clickShow"
      style="color: #303038; cursor: pointer; margin: auto"
      ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
    ></span>
  
  </div>
      <a id="urlHome" href="/" style="margin: 0 10px"
        ><img
          style="width: 35px; height: 35px"
          src="img/home-icon-white-8.jpg"
        />
      </a>
    </div>
  </header>
  <div class="wrapper row3">
    <main class="hoc container-fluid clear bv">
   
      <!-- giaoThong-->
      <div
        id="GiaoThong"
        v-show="showGiaoThong"
        style="min-height: 500px;"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleGiaoThong"></div>
        <div v-html="contentGiaoThong"></div>
      </div>
      <!-- DichVuCong -->
      <div
        id="DichVuCong"
        v-show="showDichVuCong"
        style="min-height: 500px; background-image: none !important"
        class="padding-top15"
      >
        <div class="title-bai-viet" v-html="titleDichVuCong"></div>
        <div v-html="contentDichVuCong"></div>
      </div>

      <!-- LIEN HE -->
      <div
        id="lienhe"
        v-show="showLienHe"
        style="min-height: 500px; background-image: none !important"
      >
        <div class="title-bai-viet" v-html="titleLienHe"></div>
        <div v-html="contentLienHe"></div>
      </div>
    </main>
  </div>
  <FooterView></FooterView>
</template>
  <script>
import FooterView from "@/views/Vimass/Footer/FooterView.vue";
import { languages } from "@/components/languages";
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";

export default {
  components: {
    FooterView
  },
  data() {
    return {
      activeTab: "GiaoThong",
      isMenuVisible: false,

      currentLanguage: languages.vi,
      lang: "",
      base64: "",
      base64Title: "",

      showGiaoThong: true,
      showDichVuCong: false,
      showLienHe: false,

      //active tab
      // isActiveGioiThieu: true,isActiveYte:true, isActiveGiaoDuc:false,
      isActiveGiaoThong: true,
      isActiveDichVuCong: false,
      isActiveLienHe: false,

      //title
      titleGioiThieu: "",
      titleGiaoThong: "",
      titleDichVuCong: "",
      titleLienHe: "",

      //content
      contentGioiThieu: "",
      contentGiaoThong: "",
      contentDichVuCong: "",
      contentLienHe: "",

      hasMounted: false,
    };
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
    if (!this.hasMounted) {
      this.hasMounted = true; // Set the flag to true to indicate that mounted has been executed
      this.lang = localStorage.getItem("languages");
      if (this.lang == "vi") {
        document.title = "Vimass.vn - Giao thông & dịch vụ công";
      } else if (this.lang == "en") {
        document.title = "Vimass.vn - Traffic & Public Services";
      } else if (this.lang == "cn") {
        document.title = "Vimass.vn - 交通及公共服务";
      } else if (this.lang == "kr") {
        document.title = "Vimass.vn - 교통 및 공공 서비스";
      }

      this.performPostRequest("1694514732643ut54a", "GiaoThong", this.lang);
      window.location.href = this.lang + "/giaothongvadichvucong#giaothong/";

      document.getElementById("urlHome").setAttribute("href", this.lang);
     
      document
        .getElementById("urlBai1")
        .setAttribute("href", this.lang + "/giaothongvadichvucong#giaothong/");
      document
        .getElementById("urlBai2")
        .setAttribute("href", this.lang + "/giaothongvadichvucong#dichvucong/");
      document
        .getElementById("urlBai3")
        .setAttribute("href", this.lang + "/giaothongvadichvucong#lienhe/");
    }
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      window.location.reload();
    },

    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        } else if (this.lang === "kr") {
          this.base64 = response.data.result.content_kr;
          this.base64Title = response.data.result.title_kr;
        } else {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        }

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        // Store content separately for each section
        if (section === "GiaoThong") {
          this.titleGiaoThong = decodeURIComponent(escape(title));
          this.contentGiaoThong = decodeURIComponent(escape(decodedString));
        } else if (section === "DichVuCong") {
          this.titleDichVuCong = decodeURIComponent(escape(title));
          this.contentDichVuCong = decodeURIComponent(escape(decodedString));
        } else if (section === "lienhe") {
          this.titleLienHe = decodeURIComponent(escape(title));
          this.contentLienHe = decodeURIComponent(escape(decodedString));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    toggleMenu() {
      this.isMenuVisible = true;
    },
    changeTab(tab) {
      if (window.innerWidth <= 768) {
        this.isMenuVisible = false;
      }
      this.lang = localStorage.getItem("languages");

     if (tab === "GiaoThong") {
  
        this.isActiveGiaoThong = true;
        this.isActiveDichVuCong = false;
        this.isActiveLienHe = false;
        this.performPostRequest("1694514732643ut54a", "GiaoThong", this.lang);
      } else 
      if (tab === "DichVuCong") {
  
        this.isActiveGiaoThong = false;
        this.isActiveDichVuCong = true;
        this.isActiveLienHe = false;
        this.performPostRequest("1691491192754cs1o5", "DichVuCong", this.lang);
      } else if (tab === "lienhe") {
  
        this.isActiveGiaoThong = false;
        this.isActiveDichVuCong = false;
        this.isActiveLienHe = true;
        this.performPostRequest("1690272053288yg3c7", "lienhe", this.lang);
      }

      this.activeTab = tab; // Cập nhật giá trị activeTab


      this.showGiaoThong = tab === "GiaoThong";
      this.showDichVuCong = tab === "DichVuCong";
      this.showLienHe = tab == "lienhe";
    },
  },
};
</script>
  
  