<template>
    <div class="menu_co_right" style="display: flex">
    <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
      ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
    </a>
    <a @click="changeLanguage('en')" class="lang" style="padding: 5px"
      ><img style="width: 25px" src="img/img-item-vimass-2022/co/us.png" />
    </a>
    <a @click="changeLanguage('cn')" class="lang" style="padding: 5px"
      ><img style="width: 25px" src="img/img-item-vimass-2022/co/cn.jpg" />
    </a>
    <a @click="changeLanguage('kr')" class="lang" style="padding: 5px"
      ><img style="width: 25px ; " src="img/img-item-vimass-2022/co/kr.jpg" />
    </a>

    <!-- <a @click="changeLanguage('ja')" class="lang" style="padding: 5px"
      ><img style="width: 25px ;" src="img/img-item-vimass-2022/co/ja.jpg" />
    </a> -->
    <!-- <a @click="changeLanguage('ja')" class="lang" style="padding: 5px"
      ><img style="width: 25px ;border: 1px #000 solid; " src="img/img-item-vimass-2022/co/ja.jpg" />
    </a> -->
    <span
      class="clickShow"
      style="color: #303038; cursor: pointer; margin: auto"
      ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
    ></span>
    <!-- <div class="showCo" style="display: none; margin: auto">
    
      <a onclick="changeLang('us')" class="lang"
        ><img src="img/img-item-vimass-2022/co/indo.jpg" />
      </a>
      <a onclick="changeLang('us')" class="lang"
        ><img src="img/img-item-vimass-2022/co/tl.png" />
      </a>
      <a onclick="changeLang('us')" class="lang"
        ><img src="img/img-item-vimass-2022/co/thailan.png" />
      </a>
      <a onclick="changeLang('us')" class="lang"
        ><img src="img/img-item-vimass-2022/co/hu.jpg" />
      </a>
    </div> -->
  </div>
</template>
<script>
import { languages } from "@/components/languages";
export default {
    setup() {
        
    },
    methods:{
        changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      const langReload = localStorage.getItem("languages");
      //     window.addEventListener('beforeunload', () => {
      //   localStorage.setItem("currentLanguage", lang);
      // });
      window.location.href = "/" + langReload;
    },
    }
}
</script>