<template>
  <header class="d-flex justify-content-between align-items-center">
    <label for="toggle-1" class="toggle-menu" @click="toggleMenu"
      ><ul>
        <li></li>
        <li></li>
        <li></li></ul ></label>
    <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
    <nav
      class="nav_center"
      style="padding: 0"
      :style="{ display: isMenuVisible ? 'block' : 'none' }"
    >
      <ul id="menuList">
        <li
          :class="{ topmenuactive: activeTab === 'DoiTac' }"
          @click="changeTab('DoiTac')"
        >
          <a id="urlDoiTac" href="congty#DoiTac/">{{
            currentLanguage.header_text_khachHang_doiTac
          }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'CoDong' }"
          @click="changeTab('CoDong')"
        >
          <a id="urlCoDong" href="congty#CoDong/">{{
            currentLanguage.header_text_congTy_coDong
          }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'HoiDongQuanTri' }"
          @click="changeTab('HoiDongQuanTri')"
        >
          <a id="urlHoiDongQuanTri" href="congty#HoiDongQuanTri/">{{
            currentLanguage.header_text_congTy_hoiDongQuanTri
          }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'BanKiemSoat' }"
          @click="changeTab('BanKiemSoat')"
        >
          <a id="urlBanKiemSoat" href="congty#BanKiemSoat/">{{
            currentLanguage.header_text_congTy_banKiemSoat
          }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'BanDieuHanh' }"
          @click="changeTab('BanDieuHanh')"
        >
          <a id="urlBanDieuHanh" href="congty#BanDieuHanh/"
            >{{ currentLanguage.header_text_congTy_banDieuHanh }}
          </a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'LienHe' }"
          @click="changeTab('LienHe')"
        >
          <a id="urlLienHe" href="congty#LienHe/">{{
            currentLanguage.header_text_lienHe
          }}</a>
        </li>
      </ul>
    </nav>
    <div class="menu_right" style="display: flex">
      <div class="menu_co_right" style="display: flex">
    <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
      ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
    </a>
    <a @click="changeLanguage('en')" class="lang" style="padding: 5px"
      ><img style="width: 25px" src="img/img-item-vimass-2022/co/us.png" />
    </a>
    <a @click="changeLanguage('cn')" class="lang" style="padding: 5px"
      ><img style="width: 25px" src="img/img-item-vimass-2022/co/cn.jpg" />
    </a>
    <a @click="changeLanguage('kr')" class="lang" style="padding: 5px"
      ><img style="width: 25px ; " src="img/img-item-vimass-2022/co/kr.jpg" />
    </a>

 
 
    <span
      class="clickShow"
      style="color: #303038; cursor: pointer; margin: auto"
      ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
    ></span>
  
  </div>
      <a id="urlHome" href="/" style="margin: 0 10px"
        ><img
          style="width: 35px; height: 35px"
          src="img/home-icon-white-8.jpg"
        />
      </a>
    </div>
  </header>
  <div class="wrapper row3">
    <main class="hoc container-fluid clear bv">
     
          <!-- thi diem -->
          <div
            id="DoiTac"
            v-show="showDoiTac"
            style="min-height: 500px"
            class="padding-top15"
          >
            <DoiTac></DoiTac>
          </div>
          <!-- tai tro-->
          <div
            id="CoDong"
            v-show="showCoDong"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <CoDongVue></CoDongVue>
          </div>
          <!-- Giao Duc -->
          <div
            id="HoiDongQuanTri"
            v-show="showHoiDongQuanTri"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <HoiDongQuanTriVue></HoiDongQuanTriVue>
          </div>
          <!-- suc khoe -->
          <div
            id="BanKiemSoat"
            v-show="showBanKiemSoat"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <BanKiemSoatVue></BanKiemSoatVue>
          </div>
          <!-- BanDieuHanh -->
          <div
            id="BanDieuHanh"
            v-show="showGiaoThongDVC"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <BanDieuHanhVue></BanDieuHanhVue>
          </div>
          <!-- ngan hang -->
          <div
            id="LienHe"
            v-show="showLienHe"
            style="min-height: 500px; background-image: none !important"
          >
            <div class="title-bai-viet" v-html="titleLienHe"></div>
            <div v-html="contentLienHe"></div>
          </div>
       
    </main>
  </div>
  <FooterView></FooterView>
</template>
  <script>
import FooterView from "@/views/Vimass/Footer/FooterView.vue";
import { languages } from "@/components/languages";
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";
import DoiTac from "../MainBaiVietCon/CongTy/DoiTac.vue";
import CoDongVue from "../MainBaiVietCon/CongTy/CoDong.vue";
import HoiDongQuanTriVue from "../MainBaiVietCon/CongTy/HoiDongQuanTri.vue";
import BanKiemSoatVue from "../MainBaiVietCon/CongTy/BanKiemSoat.vue";
import BanDieuHanhVue from "../MainBaiVietCon/CongTy/BanDieuHanh.vue";
export default {
  components: {
    BanDieuHanhVue,
    BanKiemSoatVue,
    HoiDongQuanTriVue,
    CoDongVue,
    DoiTac,
    FooterView
  },
  data() {
    return {
      activeTab: "DoiTac",
      isMenuVisible: false,
      currentLanguage: languages.vi,
      lang: "",
      base64: "",
      base64Title: "",

      //show
      showDoiTac: true,
      showCoDong: false,
      showHoiDongQuanTri: false,
      showBanKiemSoat: false,
      showGiaoThongDVC: false,
      showLienHe: false,

      //active tab
      isActiveDoiTac: true,
      isActiveCoDong: false,
      isActiveHoiDongQuanTri: false,
      isAcitveBanKiemSoat: false,
      isActiveGiaoThongDVC: false,
      isActiveLienHe: false,

      //title

      titleLienHe: "",

      //content

      contentLienHe: "",

      hasMounted: false,
    };
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
    if (!this.hasMounted) {
      this.hasMounted = true; // Set the flag to true to indicate that mounted has been executed
      this.lang = localStorage.getItem("languages");
      if (this.lang == "vi") {
        document.title = "Vimass.vn - Công ty";
      } else if (this.lang == "en") {
        document.title = "Vimass.vn - Company";
      } else if (this.lang == "cn") {
        document.title = "Vimass.vn - 公司";
      }else if (this.lang == "kr") {
        document.title = "Vimass.vn - 회사";
      }
      window.location.href = this.lang + "/congty#doitac/";
      document.getElementById("urlHome").setAttribute("href", this.lang);
      document
        .getElementById("urlDoiTac")
        .setAttribute("href", this.lang + "/congty#doitac/");
      document
        .getElementById("urlCoDong")
        .setAttribute("href", this.lang + "/congty#codong/");
      document
        .getElementById("urlHoiDongQuanTri")
        .setAttribute("href", this.lang + "/congty#hoidongquantri/");
      document
        .getElementById("urlBanKiemSoat")
        .setAttribute("href", this.lang + "/congty#bankiemsoat/");
      document
        .getElementById("urlBanDieuHanh")
        .setAttribute("href", this.lang + "/congty#bandieuhanh/");
      document
        .getElementById("urlLienHe")
        .setAttribute("href", this.lang + "/congty#lienhe/");
    }
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      window.location.reload();
    },

    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        }else{
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        }

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        // Store content separately for each section
        if (section === "LienHe") {
          this.titleLienHe = decodeURIComponent(escape(title));
          this.contentLienHe = decodeURIComponent(escape(decodedString));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    toggleMenu() {
      this.isMenuVisible = true;
    },
    changeTab(tab) {
      if (window.innerWidth <= 768) {
        this.isMenuVisible = false;
      }
      this.lang = localStorage.getItem("languages");

      if (tab === "DoiTac") {
        this.isActiveDoiTac = true;
        this.isActiveCoDong = false;
        this.isActiveHoiDongQuanTri = false;
        this.isAcitveBanKiemSoat = false;
        this.isActiveGiaoThongDVC = false;
        this.isActiveLienHe = false;
      } else if (tab === "CoDong") {
        this.isActiveDoiTac = false;
        this.isActiveCoDong = true;
        this.isActiveHoiDongQuanTri = false;
        this.isAcitveBanKiemSoat = false;
        this.isActiveGiaoThongDVC = false;
        this.isActiveLienHe = false;
      } else if (tab === "HoiDongQuanTri") {
        this.isActiveDoiTac = false;
        this.isActiveCoDong = false;
        this.isActiveHoiDongQuanTri = true;
        this.isAcitveBanKiemSoat = false;
        this.isActiveGiaoThongDVC = false;
        this.isActiveLienHe = false;
      } else if (tab === "BanKiemSoat") {
        this.isActiveDoiTac = false;
        this.isActiveCoDong = false;
        this.isActiveHoiDongQuanTri = false;
        this.isAcitveBanKiemSoat = true;
        this.isActiveGiaoThongDVC = false;
        this.isActiveLienHe = false;
      } else if (tab === "BanDieuHanh") {
        this.isActiveDoiTac = false;
        this.isActiveCoDong = false;
        this.isActiveHoiDongQuanTri = false;
        this.isAcitveBanKiemSoat = false;
        this.isActiveGiaoThongDVC = true;
        this.isActiveLienHe = false;
      } else if (tab === "LienHe") {
        this.isActiveDoiTac = false;
        this.isActiveCoDong = false;
        this.isActiveHoiDongQuanTri = false;
        this.isAcitveBanKiemSoat = false;
        this.isActiveGiaoThongDVC = false;
        this.isActiveLienHe = true;
        this.performPostRequest("1690272053288yg3c7", "LienHe", this.lang);
      }

      this.activeTab = tab; // Cập nhật giá trị activeTab

      this.showDoiTac = tab === "DoiTac";
      this.showCoDong = tab === "CoDong";
      this.showHoiDongQuanTri = tab === "HoiDongQuanTri";
      this.showBanKiemSoat = tab === "BanKiemSoat";
      this.showGiaoThongDVC = tab === "BanDieuHanh";
      this.showLienHe = tab == "LienHe";
    },
  },
};
</script>
  
  