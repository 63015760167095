<template>
        <div id ="doitac" style="display : block ;">
         
            <div class="kh_framer_row">
                    <h2 class="title_kh_unit">Ngân hàng đồng hành

                    </h2>
              
                <div class="row_kh" >
                   <div class="row_kh_inside d-flex algin-items-center">
                    <!-- <div class="m-3 d-flex justify-content-center algin-item-center"> -->
                        <div class="row_kh_col" >
                           <a target="_blank" href="#">
                            <img src="img/Logo_khachhang/logo_BIDV.png">
                           </a>
                        </div>
                        <div class="row_kh_col" >
                            <a target="_blank" href="#">
                                <img src="img/Logo_khachhang/logo_VPB.png" />
                            </a>
                        </div>
                 
                        <div class="row_kh_col" >
                            <a target="_blank" href="#">
                                <img src="img/Logo_khachhang/logo_TPB.png" />

                            <!-- <img src="img/Logo_khachhang/ICB.png"> -->
                            </a>
                        </div>
                        <div class="row_kh_col" >
                            <a target="_blank" href="#">
                                <img src="img/Logo_khachhang/logo_PV.png" style="width: 65%!important;" />
                            </a>
                        </div>
                        <div class="row_kh_col" >
                            <a target="_blank" href="#">
                                <img src="img/Logo_khachhang/logo_NAB.png" />

                            <!-- <img src="img/Logo_khachhang/logo_VBA.png"> -->
                            </a>
                        </div>
                    </div>
                </div>
            </div>
  
            <!-- <div class="kh_framer_row_1">
                    <h2 class="title_kh_unit">Doanh nghiêp CNTT đồng hành

                    </h2>
               
                <div class="wrapper1">
                    <div class="slider1">
                        <div class="slide1">
                            <img src="img/Logo_khachhang/logo_VCB.png">
                            <img src="img/Logo_khachhang/logo_VBA.png">
                            <img src="img/Logo_khachhang/ICB.png">  -->
                            <!-- <img src="img/Logo_khachhang/logo_VPB.png" /> -->
                            <!-- <img src="img/Logo_khachhang/TCB.png" />
                            <img src="img/Logo_khachhang/logo_LPB.png" />
                            <img src="img/Logo_khachhang/STB.png" /> -->
                            <!-- <img src="img/Logo_khachhang/logo_TPB.png" />
                            <img src="img/Logo_khachhang/logo_NAB.png" /> -->
                            <!-- <img src="img/Logo_khachhang/logo_mb.png" />
                            <img src="img/Logo_khachhang/ACB.png" />
                            <img src="img/Logo_khachhang/logo_ABB.png" />

                        </div>
                        <div class="slide1">
                            <img src="img/Logo_khachhang/logo_VCB.png">
                            <img src="img/Logo_khachhang/logo_VBA.png">
                            <img src="img/Logo_khachhang/ICB.png">  -->
                            <!-- <img src="img/Logo_khachhang/logo_VPB.png" /> -->
                            <!-- <img src="img/Logo_khachhang/TCB.png" />
                            <img src="img/Logo_khachhang/logo_LPB.png" />
                            <img src="img/Logo_khachhang/STB.png" /> -->
                            <!-- <img src="img/Logo_khachhang/logo_TPB.png" /> -->
                            <!-- <img src="img/Logo_khachhang/logo_NAB.png" /> -->
                            <!-- <img src="img/Logo_khachhang/logo_mb.png" />
                            <img src="img/Logo_khachhang/ACB.png" />
                            <img src="img/Logo_khachhang/logo_ABB.png" />
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- @*row2*@
            <div class="kh_framer_row_2">
                    <h2 class="title_kh_unit"> Trung gian thanh toán </h2> <br>

              
                <div class="row_kh_2" >
                    <div class="row_kh_inside_2">
                        <div class="row_kh_col_2" >


                            <img src="img/Logo_khachhang/logo_Napas.png">
                        </div>
                        <div class="row_kh_col_2" >

                            <img src="img/Logo_khachhang/logo_Vnpay.png">
                        </div>
                        <div class="row_kh_col_2" >

                            <img src="img/Logo_khachhang/logo_Viettel_Money.png">
                        </div>
                        <div class="row_kh_col_2" >

                            <img src="img/Logo_khachhang/logo_Vnpt_Pay.png">
                        </div>
                        <div class="row_kh_col_2" style="border-right: none;">

                            <img src="img/Logo_khachhang/logo_Mobifone_Pay.png">
                        </div>

                        <div class="row_kh_col_2" >

                            <img src="img/Logo_khachhang/MoMo.png">
                        </div>
                        <div class="row_kh_col_2" style="border-right: none;">

                            <img src="img/Logo_khachhang/ZaloPay.png">
                        </div>
                        <div class="row_kh_col_2" style="border-right: none;">

                            <img src="img/Logo_khachhang/logo_Vinid.png">
                        </div>
                        <div class="row_kh_col_2" >

                            <img src="img/Logo_khachhang/logo_PayTech.png">
                        </div>


                    </div>
                </div>
            </div> -->
            <div class="kh_framer_row_2">
                    <h2 class="title_kh_unit">Doanh nghiêp CNTT đồng hành</h2> <br>

               
                <div class="row_kh_2" >
                    <div class="row_kh_inside_2_1">
                        <div class="row_kh_col_2_1" style="border-right: 1px solid #dddde8;;" >

                            <img src="img/Logo_khachhang/logo_fpt_is.png">
                        </div>
                        <div class="row_kh_col_2_1" >
                            <img src="img/Logo_khachhang/logo_cmc_tp.png">
                        </div>
                    </div>
                </div>
            </div>


        </div>
</template>
<style scoped>
.wrapper1 {
    display: flex;
    align-items: center;
    justify-content: center;
}
.slider1 {
    position: relative;
    background: white;
    box-shadow: 0 10px 20px -10px rgba(0,0,0,0.2);
    display: flex;
    overflow: hidden;
}
.slide1 {
    height: 100px;
    display: flex;
    align-items: center;
    animation: slideshow 20s linear infinite;
}
.slide1 img {
    height: 125px;
    padding: 0 30px 0 30px;
    max-width: none;
}
@keyframes slideshow {
    0% {transform: translateX(0);}
    100% {transform: translateX(-100%);}
}
.slider1::before, .slider1::after {
    height: 100px;
    width: 200px;
    position: absolute;
    content: "";
    background: linear-gradient(to right, white 0%, rgba(255,255,255,0) 100%);
    z-index: 2;
}
.slider1::before {
    left: 0;
    top: 0;
}
.slider1::after {
    right:0;
    top:0;
    transform: rotateZ(180deg);
}
</style>