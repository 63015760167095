<template>
  <header class="d-flex justify-content-between align-items-center">
    <label for="toggle-1" class="toggle-menu" @click="toggleMenu"
      ><ul>
        <li></li>
        <li></li>
        <li></li></ul
    ></label>
    <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
    <nav
      class="nav_center"
      style="padding: 0"
      :style="{ display: isMenuVisible ? 'block' : 'none' }"
    >
      <ul style="list-style: none">
        <li
          :class="{ topmenuactive: activeTab === 'gioiThieu' }"
          @click="changeTab('gioiThieu')"
        >
          <a id="urlGioithieu" href="dichvuthuchiho#gioithieu/">{{
            currentLanguage.header_text_gioiThieu
          }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'DiemThanhToan' }"
          @click="changeTab('DiemThanhToan')"
        >
          <a id="urlBai1" href="dichvuthuchiho#hopdong/">{{
            currentLanguage.body_HopDong_text_title
          }}</a>
        </li>
        <li
          :class="{ topmenuactive: activeTab === 'lienhe' }"
          @click="changeTab('lienhe')"
        >
          <a id="urlBai3" href="dichvuthuchiho#lienhe/">{{
            currentLanguage.header_text_lienHe
          }}</a>
        </li>
        <!-- <li
          :class="{ topmenuactive: activeTab === 'DiemThanhToan' }"
          @click="changeTab('DiemThanhToan')"
        >
          <a id="urlBai1" href="dichvuthuchiho#diemthanhtoan/">{{
            currentLanguage.footer_text_diemThanhToan
          }}</a>
        </li>
      

        <li
          :class="{ topmenuactive: activeTab === 'lienhe' }"
          @click="changeTab('lienhe')"
        >
          <a id="urlBai3" href="dichvuthuchiho#lienhe/">{{
            currentLanguage.header_text_lienHe
          }}</a>
        </li> -->
      </ul>
    </nav>
    <div class="menu_right" style="display: flex">
      <div class="menu_co_right" style="display: flex">
    <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
      ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
    </a>
    <a @click="changeLanguage('en')" class="lang" style="padding: 5px"
      ><img style="width: 25px" src="img/img-item-vimass-2022/co/us.png" />
    </a>
    <a @click="changeLanguage('cn')" class="lang" style="padding: 5px"
      ><img style="width: 25px" src="img/img-item-vimass-2022/co/cn.jpg" />
    </a>
    <a @click="changeLanguage('kr')" class="lang" style="padding: 5px"
      ><img style="width: 25px ; " src="img/img-item-vimass-2022/co/kr.jpg" />
    </a>

    <span
      class="clickShow"
      style="color: #303038; cursor: pointer; margin: auto"
      ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
    ></span>
      <a id="urlHome" href="/" style="margin: 0 10px"
        ><img
          style="width: 35px; height: 35px"
          src="img/home-icon-white-8.jpg"
        />
      </a>
    </div>
    </div>
  </header>
  <div class="wrapper row3">
    <main class="hoc container-fluid clear bv">
     
          <!-- gioi thieu -->
          <div
            id="gioiThieu"
            v-show="showGioiThieu"
            style="min-height: 500px"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleGioiThieu"></div>
            <div v-html="contentGioiThieu"></div>
          </div>
          <!-- DiemThanhToan-->
          <div
            id="DiemThanhToan"
            v-show="showDiemThanhToan"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleDiemThanhToan"></div>
            <div v-html="contentDiemThanhToan"></div>
          </div>
          <!-- DiemDinhDanh -->
          <div
            id="DiemDinhDanh"
            v-show="showDiemDinhDanh"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleDiemDinhDanh"></div>
            <div v-html="contentDiemDinhDanh"></div>
          </div>

          <!-- LIEN HE -->
          <div
            id="lienhe"
            v-show="showLienHe"
            style="min-height: 500px; background-image: none !important"
          >
            <div class="title-bai-viet" v-html="titleLienHe"></div>
            <div v-html="contentLienHe"></div>
          </div>
        
    </main>
  </div>
  <FooterView></FooterView>
</template>
  <script>
import FooterView from "@/views/Vimass/Footer/FooterView.vue";
import { languages } from "@/components/languages";
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";
export default {
  components: {
    FooterView,
  },
  data() {
    return {
      activeTab: "gioiThieu",
      isMenuVisible: false,

      currentLanguage: languages.vi,
      lang: "",
      base64: "",
      base64Title: "",

      //show
      showGioiThieu: true,
      showDiemThanhToan: false,
      showDiemDinhDanh: false,
      showLienHe: false,

      //active tab
      isActiveGioiThieu: true,
      isActiveDiemThanhToan: false,
      isActiveDiemDinhDanh: false,
      isActiveLienHe: false,

      //title
      titleGioiThieu: "",
      titleDiemThanhToan: "",
      titleDiemDinhDanh: "",
      titleLienHe: "",

      //content
      contentGioiThieu: "",
      contentDiemThanhToan: "",
      contentDiemDinhDanh: "",
      contentLienHe: "",

      hasMounted: false,
    };
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
    if (!this.hasMounted) {
      this.hasMounted = true; // Set the flag to true to indicate that mounted has been executed
      this.lang = localStorage.getItem("languages");
      if (this.lang == "vi") {
        document.title = "Vimass.vn - Dịch vụ thu hộ, chi hộ";
      } else if (this.lang == "en") {
        document.title = "Vimass.vn - Collection and payment services";
      } else if (this.lang == "cn") {
        document.title = "Vimass.vn - 代收代付服务";
      }else if (this.lang == "kr") {
        document.title = "Vimass.vn - 수집 및 지불 서비스";
      }
      this.performPostRequest("1719997599950aUoQY", "gioiThieu", this.lang);

      window.location.href = this.lang + "/dichvuthuchiho#gioithieu";
      document.getElementById("urlHome").setAttribute("href", this.lang);
      document
        .getElementById("urlGioithieu")
        .setAttribute(
          "href",
          this.lang + "/dichvuthuchiho#gioithieu"
        );
      document
        .getElementById("urlBai1")
        .setAttribute(
          "href",
          this.lang + "/dichvuthuchiho#hopdong"
        );
      // document
      //   .getElementById("urlBai2")
      //   .setAttribute(
      //     "href",
      //     this.lang + "/dichvuthuchiho#diemdinhdanh/"
      //   );
      document
        .getElementById("urlBai3")
        .setAttribute("href", this.lang + "/dichvuthuchiho#lienhe");
    }
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      window.location.reload();
    },

    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        }else if (this.lang === "kr") {
          this.base64 = response.data.result.content_kr;
          this.base64Title = response.data.result.title_kr;
        }else{
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        }

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        // Store content separately for each section
        if (section === "gioiThieu") {
          this.titleGioiThieu = decodeURIComponent(escape(title));
          this.contentGioiThieu = decodeURIComponent(escape(decodedString));
        } else if (section === "DiemThanhToan") {
          this.titleDiemThanhToan = decodeURIComponent(escape(title));
          this.contentDiemThanhToan = decodeURIComponent(escape(decodedString));
        } else if (section === "DiemDinhDanh") {
          this.titleDiemDinhDanh = decodeURIComponent(escape(title));
          this.contentDiemDinhDanh = decodeURIComponent(escape(decodedString));
        } else if (section === "lienhe") {
          this.titleLienHe = decodeURIComponent(escape(title));
          this.contentLienHe = decodeURIComponent(escape(decodedString));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    toggleMenu() {
      this.isMenuVisible = true;
    },
    changeTab(tab) {
      if (window.innerWidth <= 768) {
        this.isMenuVisible = false;
      }
      this.lang = localStorage.getItem("languages");

      if (tab === "gioiThieu") {
        this.isActiveGioiThieu = true;
        this.isActiveDiemThanhToan = false;
        this.isActiveDiemDinhDanh = false;
        this.isActiveLienHe = false;
        this.performPostRequest("1719997599950aUoQY", "gioiThieu", this.lang);
      } else if (tab === "DiemThanhToan") {
        this.isActiveGioiThieu = false;
        this.isActiveDiemThanhToan = true;
        this.isActiveDiemDinhDanh = false;
        this.isActiveLienHe = false;
        this.performPostRequest(
          "17200780550769PnFB",
          "DiemThanhToan",
          this.lang
        );
      } else if (tab === "DiemDinhDanh") {
        this.isActiveGioiThieu = false;
        this.isActiveDiemThanhToan = false;
        this.isActiveDiemDinhDanh = true;
        this.isActiveLienHe = false;
        this.performPostRequest(
          "1684483847412mqp7d",
          "DiemDinhDanh",
          this.lang
        );
      } else if (tab === "lienhe") {
        this.isActiveGioiThieu = false;
        this.isActiveDiemThanhToan = false;
        this.isActiveDiemDinhDanh = false;
        this.isActiveLienHe = true;
        this.performPostRequest("1690272053288yg3c7", "lienhe", this.lang);
      }

      this.activeTab = tab; // Cập nhật giá trị activeTab

      this.showGioiThieu = tab === "gioiThieu";
      this.showDiemThanhToan = tab === "DiemThanhToan";
      this.showDiemDinhDanh = tab === "DiemDinhDanh";
      this.showLienHe = tab == "lienhe";
    },
  },
};
</script>
  
  