<!-- //
//                _ooOoo_                                     NAM MÔ A DI ĐÀ PHẬT !
//               o8888888o
//               88" . "88                              Thí chủ con tên là Chử Văn Viên
//               (| -_- |)                            dương lịch ngày 8 tháng 10 năm 1999
//                O\ = /O
//            ____/`---'\____                  Con lạy chín phương trời, con lạy mười phương đất
//            .' \\| |// `.                       Chư Phật mười phương, mười phương chư Phật
//           / \\||| : |||// \                    Con ơn nhờ Trời đất chổ che, Thánh Thần cứu độ
//         / _||||| -:- |||||- \             Xin nhất tâm kính lễ Hoàng thiên Hậu thổ, Tiên Phật Thánh Thần
//           | | \\\ - /// | |                           Giúp đỡ con code sạch ít bug
//         | \_| ''\---/'' | | |                    Biểu diễn ko lỗi, sếp quý tăng lương
//         \ .-\__ `-` ___/-. /                       Sức khoẻ dồi dào, tiền vào như nước
//       ___`. .' /--.--\ `. . __
//    ."" '< `.___\_<|>_/___.' >'"".         NAM MÔ VIÊN THÔNG GIÁO CHỦ ĐẠI TỪ ĐẠI BI TẦM THANH CỨU KHỔ CỨU NẠN
//   | | : `- \`.;`\ _ /`;.`/ - ` : | |              QUẢNG ĐẠI LINH CẢM QUÁN THẾ ÂM BỒ TÁT
//     \ \ `-. \_ __\ /__ _/ .-` / /
//======`-.____`-.___\_____/___.-`____.-'======
//                `=---='
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
//
  -->
<template>
  
  <div class="banner bannerWeb" v-if="isBannerWeb">
    <div class="inner">
      <!-- <div id="one" class="item first">
        <img :src="currentLanguage.banner_web_3" />
      </div>
      <div id="two" class="item second">
        <img :src="currentLanguage.banner_web_2" />
      </div> -->

      <div id="one" class="item first" >
        <img :src="currentLanguage.banner_web_1" />
      </div>

      <div id="two" class="item second">
        <img :src="currentLanguage.banner_web_2" />

      </div>

      <div id="three" class="item third">
        <img :src="currentLanguage.banner_web_1" />

      </div>
      <div id="four" class="item fourth">
        <img :src="currentLanguage.banner_web_2" />

      </div>

    </div>
  </div>

  
  <div class="banner bannerPhone " >
    <div class="inner">
      <div id="one" class="item first">
        <img :src="currentLanguage.banner_phone_1" />
      </div>
      <div id="two" class="item second">
        <img :src="currentLanguage.banner_phone_2" />
      </div>
      <div id="three" class="item third">
        <img :src="currentLanguage.banner_phone_1" />
      </div>
      <div id="four" class="item fourth">
        <img :src="currentLanguage.banner_phone_2" />
      </div>
    </div>
  </div>

  <div class="firework"></div>

  <!-- <div class="menu_co_right" style="display: flex">
    <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
      ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
    </a>
    <a @click="changeLanguage('en')" class="lang" style="padding: 5px"
      ><img style="width: 25px" src="img/img-item-vimass-2022/co/us.png" />
    </a>
    <a @click="changeLanguage('cn')" class="lang" style="padding: 5px"
      ><img style="width: 25px" src="img/img-item-vimass-2022/co/cn.jpg" />
    </a>
    <a @click="changeLanguage('kr')" class="lang" style="padding: 5px"
      ><img style="width: 25px ;border: 1px #000 solid; " src="img/img-item-vimass-2022/co/kr.jpg" />
    </a>
    <a @click="changeLanguage('jp')" class="lang" style="padding: 5px"
      ><img style="width: 25px ;border: 1px #000 solid; " src="img/img-item-vimass-2022/co/ja.jpg" />
    </a>
    <span
      class="clickShow"
      style="color: #303038; cursor: pointer; margin: auto"
      ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
    ></span>
    <div class="showCo" style="display: none; margin: auto">
    
      <a onclick="changeLang('us')" class="lang"
        ><img src="img/img-item-vimass-2022/co/indo.jpg" />
      </a>
      <a onclick="changeLang('us')" class="lang"
        ><img src="img/img-item-vimass-2022/co/tl.png" />
      </a>
      <a onclick="changeLang('us')" class="lang"
        ><img src="img/img-item-vimass-2022/co/thailan.png" />
      </a>
      <a onclick="changeLang('us')" class="lang"
        ><img src="img/img-item-vimass-2022/co/hu.jpg" />
      </a>
    </div>
  </div> -->
  <main-lang></main-lang>
  <div class="wrapper row3">
    <main class="hoc container-fluid clear">
      <div class="row">
        <div class="col-12 col-md-12 p-0">

          <!-- 1 Thu tiền, bán hàng bằng qr âm thanh
          2 thu chi hộ
          3 giải pháp
          4 thiết bị và dịch vu 
          5 giáo dục và y tế
          6 giao thông dịch vụ công
          7 dtt và định danh
          8 công ty -->
          
        
          <!-- <article class="col-12 col-md-3 p-2 one_fifth dv">
            <a
              id="qrAmThanh"
              class="btmspace-50"
              href="/qramthanh"

            >
              <img
                class="imgmenu"
                style="border-radius: 8px"
                :src="currentLanguage.main_images_qrAMtHANH"
              />
            </a>
          </article>
          <article class="col-12 col-md-3 p-2 one_fifth">
            <a id="giaoDuc" class="btmspace-50" href="/vpos">
              <img
                class="imgmenu"
                style="border-radius: 10px"
                :src="currentLanguage.main_images_home_giaoduc"
              />
            </a>
          </article> -->
          <article class="col-12 col-md-3 p-2 one_fifth dv">
            <a
              id="qrAmThanh"
              class="btmspace-50"
              href="/pos"
            >
              <img
                class="imgmenu"
                style="border-radius: 8px"
                :src="currentLanguage.main_images_qrAMtHANH"
              />
            </a>
          </article>

          <article class="col-12 col-md-3 p-2 one_fifth">
            <a id="yTe" class="btmspace-50" href="/dichvuthuchiho" >
              <img
                class="imgmenu"
                style="border-radius: 10px"
                :src="currentLanguage.main_images_home_yte"
              />
            </a>
          </article>
          <article class="col-12 col-md-3 p-2 one_fifth dv">
            <a
              id="diemThanhToanVaDinhDanh"
              class="btmspace-50"
              href="/diemthanhtoanvadinhdanh"
           
            >
              <img
                class="imgmenu"
                style="border-radius: 10px"
                :src="currentLanguage.main_images_home_diemgiaodich"
              />
            </a>
          </article>
          <article class="col-12 col-md-3 p-2 one_fifth dv">
            <a
              id="giaiPhapChuyenDoiSo"
              class="btmspace-50"
              href="/giaiphapchuyendoiso" 
            >
              <img
                class="imgmenu"
                style="border-radius: 10px"
                :src="currentLanguage.main_images_home_ungDung"
              />
            </a>
          </article>

          
          
         
         
         
        </div>
        <div class="col-12 col-md-12 p-0">
          <article class="col-12 col-md-3 p-2 one_fifth dv">
            <a
              id="gdYte"
              class="btmspace-50"
              href="/giaoducvayte"
           
            >
              <img
                style="border-radius: 10px"
                class="imgmenu"
                :src="currentLanguage.main_images_home_GD_YTE"
              />
            </a>
          </article>

          <article class="col-12 col-md-3 p-2 one_fifth dv">
            <a
              id="giaoThongVaDichVuCong"
              class="btmspace-50"
              href="/giaothongvadichvucong"
           
            >
              <img
                style="border-radius: 10px"
                class="imgmenu"
                :src="currentLanguage.main_images_home_giaothong"
              />
            </a>
          </article>

          <article class="col-12 col-md-3 p-2 one_fifth dv">
            <a
              id="thietBiVaDichVu"
              class="btmspace-50"
              href="/thietbivadichvu"
            
            >
              <img
                class="imgmenu"
                style="border-radius: 10px"
                :src="currentLanguage.main_images_dichVu"
              />
            </a>
          </article>
        


          <article class="col-12 col-md-3 p-2 one_fifth">
            <a id="congTy" class="btmspace-50" href="/congty" >
              <img
                class="imgmenu"
                style="border-radius: 10px"
                :src="currentLanguage.main_images_congTy"
              />
            </a>
          </article>
        </div>
      </div>

      <div class="clear"></div>
    </main>
  </div>
  <div class="wrapper row3 pt-4">
    <section class="hoc container-fluid clear p-0">
      <div class="bx-slider1">
        <MainSlideBaiViet></MainSlideBaiViet>
      </div>
    </section>
  </div>

  <FooterView></FooterView>
</template>

   
 <script>
import { languages } from "@/components/languages";
import FooterView from "@/views/Vimass/Footer/FooterView.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
// import { apiLayChiTietDanhMuc } from "@/components/listService";
// import axios from "axios";
import MainSlideBaiViet from "@/views/Vimass/Home/MainSlideBaiViet.vue";
import MainLang from '../Language/MainLang.vue';
export default {
  components: {
    MainSlideBaiViet,
    FooterView,
    MainLang,
  },
  data() {
    return {
      Swiper,
      SwiperSlide,
      isBannerPhone: false,
      isBannerWeb : true,
      currentLanguage: languages.vi,
      listBaiViet: [],
      pathname: "",
      isMobile: false,
      hasMounted: false,
    };
  },
  watch: {
    $router(to) {
      const lang = to.params.lang;
      if (languages[lang]) {
        this.currentLanguage = languages[lang];
      }
    },
  },

  mounted() {
    localStorage.setItem("vienne", "JSON.stringify(response.result)");
console.log("hahahah" + localStorage.getItem("vienne"));
    this.isMobile = window.innerWidth <= 768;
    if (!this.hasMounted) {
      this.hasMounted = true;
      this.pathname = window.location.pathname;
      var url = this.pathname.replace("/", "");
      localStorage.setItem("languages", url);
      const lang = localStorage.getItem("languages");

      document
        .getElementById("giaiPhapChuyenDoiSo")
        .setAttribute("href", lang + "/giaiphapchuyendoiso");
      document
        .getElementById("qrAmThanh")
        .setAttribute("href", lang + "/pos");
      document
        .getElementById("gdYte")
        .setAttribute("href",lang + "/giaoducvayte");
      document.getElementById("yTe").setAttribute("href", lang + "/dichvuthuchiho");
      document
        .getElementById("giaoThongVaDichVuCong")
        .setAttribute("href", lang + "/giaothongvadichvucong");
      document
        .getElementById("diemThanhToanVaDinhDanh")
        .setAttribute("href", lang + "/diemthanhtoanvadinhdanh");
      document
        .getElementById("thietBiVaDichVu")
        .setAttribute("href", lang + "/thietbivadichvu");
      document.getElementById("congTy").setAttribute("href", lang + "/congty");
    }
  },
  created() {
    // this.performPostlistBaiViet();
    const lang = this.$route.params.lang;
    if (languages[lang]) {
      this.currentLanguage = languages[lang];
    }
    const savedLanguage = localStorage.getItem("languages");

    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },

  methods: {
    // changeLanguage(lang) {
    //   this.currentLanguage = languages[lang];
    //   localStorage.setItem("languages", lang);
    //   const langReload = localStorage.getItem("languages");
    //   //     window.addEventListener('beforeunload', () => {
    //   //   localStorage.setItem("currentLanguage", lang);
    //   // });
    //   window.location.href = "/" + langReload;
    // },
    checkIfMobile() {
      this.isMobile = window.innerWidth < 768;
    },
  },
};
</script>


<style>

.banner{
  width: 100%;
  float: left;
  overflow: hidden;
}

.container,
.item {
  width: 100%;
  height: auto;
  line-height: 100px;
}
.container,
.item img {
  width: 100%;
  height: auto;
  line-height: 100px;
}

.container {
  margin: 0 auto;
  /* height: 200px; */
  text-align: center;
  /* background: #002244;
  position: relative; */
  overflow: hidden;
  border-radius: 10px;
}
.inner {position: relative; width: 100%; float: left; display: inline;}
.item {
  position: absolute;
  top: 0;
  left: 500px;
}
.first {
  left: 0;
  opacity: 1;
  z-index: 0;
}
.banner:hover .item {
  animation-play-state: paused;
  cursor: pointer;
}
.first {
  animation: cycleone 35s ease-in-out infinite;
  width: 100%; float: left; display: contents;
}
.second {
  animation: cycletwo 35s ease-in-out infinite;
  
}
.third {
  animation: cyclethree 35s ease-in-out infinite;
}
.fourth {
  animation: cyclefour 35s ease-in-out infinite;
}
@keyframes cycleone {
  0% {
    left: 0px;
    opacity: 1;
    z-index: 0;
  }
  20% {
    left: 0px;
    opacity: 1;
    z-index: 0;
  }
  25% {
    left: -500px;
    opacity: 0;
    z-index: 0;
  }
  26% {
    left: -500px;
    opacity: 0;
    z-index: -1;
  }
  27% {
    left: 500px;
    opacity: 0;
    z-index: -1;
  }
  95% {
    left: 500px;
    opacity: 0;
    z-index: 0;
  }
  100% {
    left: 0px;
    opacity: 1;
    z-index: 0;
  }
}
@keyframes cycletwo {
  0% {
    left: 500px;
    opacity: 0;
  }
  20% {
    left: 500px;
    opacity: 0;
  }
  25% {
    left: 0px;
    opacity: 1;
  }
  45% {
    left: 0px;
    opacity: 1;
    z-index: 0;
  }
  50% {
    left: -500px;
    opacity: 0;
    z-index: 0;
  }
  51% {
    left: 500px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    left: 500px;
    opacity: 0;
    z-index: -1;
  }
}
@keyframes cyclethree {
  0% {
    left: 500px;
    opacity: 0;
  }
  45% {
    left: 500px;
    opacity: 0;
    z-index: -1;
  }
  50% {
    left: 0px;
    opacity: 1;
    z-index: 0;
  }
  70% {
    left: 0px;
    opacity: 1;
    z-index: 0;
  }
  75% {
    left: -500px;
    opacity: 0;
    z-index: 0;
  }
  76% {
    left: 500px;
    opacity: 0;
    z-index: -1;
  }
  100% {
    left: 500px;
    opacity: 0;
    z-index: -1;
  }
}
@keyframes cyclefour {
  0% {
    left: 500px;
    opacity: 0;
    z-index: -1;
  }
  70% {
    left: 500px;
    opacity: 0;
    z-index: 0;
  }
  75% {
    left: 0px;
    opacity: 1;
    z-index: 0;
  }
  95% {
    left: 0px;
    opacity: 1;
    z-index: 0;
  }
  100% {
    left: -500px;
    opacity: 0;
    z-index: 0;
  }
}

@font-face {
  font-family: "Seahawks";
  src: url("https://dylanjharris.net/wp-content/themes/_djh/fonts/seahawks.eot");
  src: url("https://dylanjharris.net/wp-content/themes/_djh/fonts/seahawks.eot?#iefix")
      format("embedded-opentype"),
    url("https://dylanjharris.net/wp-content/themes/_djh/fonts/seahawks.woff2")
      format("woff2"),
    url("https://dylanjharris.net/wp-content/themes/_djh/fonts/seahawks.woff")
      format("woff"),
    url("https://dylanjharris.net/wp-content/themes/_djh/fonts/seahawks.ttf")
      format("truetype"),
    url("https://dylanjharris.net/wp-content/themes/_djh/fonts/seahawks.svg#svgFontName")
      format("svg");
}

body {
  font-family: Seahawks;
}

.tcb-product-slider {
  background: #333;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
}
.tcb-product-slider .carousel-control {
  width: 5%;
}
.tcb-product-item a {
  color: #147196;
}
.tcb-product-item a:hover {
  text-decoration: none;
}
.tcb-product-item .tcb-hline {
  margin: 10px 0;
  height: 1px;
  background: #ccc;
}

@media screen and (min-width: 2560px) {
  .container {
    height: 230px; 
  }
}


@media screen and (min-width: 3840px) {
  .container {
    height: 250px; 
  }
}
@media all and (max-width: 1694px) {
  .container-fluid1{
  margin-right: auto;
  margin-left: auto;
  margin: 0 auto;
  height: 158px;
  text-align: center;
  overflow: hidden;
  border-radius: 0px;
}
}
@media all and (max-width: 1366px) {
  .container-fluid1{
  margin-right: auto;
  margin-left: auto;
  margin: 0 auto;
  height: 140px;
  text-align: center;
  overflow: hidden;
  border-radius: 0px;
}
}
@media all and (max-width: 768px) {
  /* .item {
  position: fixed;
  top: 0;
  left: 500px;
} */
.container-fluid1,
  .container,
  .item {
    width: 100vw;
  }

  .item {
    left: 0;
  }
.container {
  margin: 0 auto;
  height: 180px;
  text-align: center;
  /* background: #002244;
  position: relative; */
  overflow: hidden;
  border-radius: 0px;
}
.inner {position: relative;}
  .tcb-product-item {
    margin-bottom: 30px;
  }
}
.tcb-product-photo {
  text-align: center;
  height: 180px;
  background: #fff;
}
.tcb-product-photo img {
  height: 100%;
  display: inline-block;
}
.tcb-product-info {
  background: #f0f0f0;
  padding: 15px;
}
.tcb-product-title h4 {
  margin-top: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tcb-product-rating {
  color: #acacac;
}
.tcb-product-rating .active {
  color: #ffb500;
}
.tcb-product-price {
  color: firebrick;
  font-size: 18px;
}

.details {
  margin: 50px 0;
}
.details h1 {
  font-size: 32px;
  text-align: center;
  margin-bottom: 3px;
}
.details .back-link {
  text-align: center;
}
.details .back-link a {
  display: inline-block;
  margin: 20px 0;
  padding: 15px 30px;
  background: #333;
  color: #fff;
  border-radius: 24px;
}
.details .back-link a svg {
  margin-right: 10px;
  vertical-align: text-top;
  display: inline-block;
}
</style> 
